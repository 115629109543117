import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { FilterComponent } from './filterComponent';
import axios from 'axios';
import { getFiltersByExistingProducts } from '../../util/getFiltersByExistingProducts';
import { filterProducts } from '../../util/filterProducts';

export const ProductFilterTile = React.memo(
	({
		pbData,
		filteredPbData,
		filterData,
		setFilteredPbData,
		selectedGroup,
		setFilteredQuickData,
		filteredItems,
		setSelectedGroup,
		isUSCustomer,
		fieldToCompare,
		isOrpFilter = false,
		filterClicked,
		additionalData,
		topFilterClicked,
		getProducts,
		productCount
	}) => {
		const [show, setShow] = useState(false);
		const [filterItemsCount, setFilterItemsCount] = useState(0);
		const [removedFiltersFlag, setRemoveFiltersFlag] = useState(false);
		const SortValues = [
			'SORT',
			'PRODUCT NAME[A-Z]',
			'PRODUCT NAME[Z-A]',
			'PRODUCT CODE',
			'PRICE LOW TO HIGH',
			'PRICE HIGH TO LOW',
		];
		const searchParamsItems = new URLSearchParams(
			decodeURIComponent(window.location.search)
		)
			?.get('filter')
			?.split(',');

		const handleSort = (sortBy) => {
			const initialPBObj =
				filteredPbData && Object.keys(filteredPbData)?.length > 0
					? filteredPbData
					: pbData;
			let sortedProductsObj = {};
			if (sortBy === 'PRICE LOW TO HIGH') {
				sortedProductsObj = {
					products: initialPBObj.products?.sort(
						(a, b) =>
							(a.productMinPrice || a.retailPrice) -
							(b.productMinPrice || b.retailPrice)
					),
				};
				setFilteredPbData(sortedProductsObj);
			} else if (sortBy === 'PRICE HIGH TO LOW') {
				sortedProductsObj = {
					products: initialPBObj.products?.sort(
						(a, b) =>
							(b.productMinPrice || b.retailPrice) -
							(a.productMinPrice || a.retailPrice)
					),
				};
				setFilteredPbData(sortedProductsObj);
			} else if (sortBy === 'PRODUCT NAME[A-Z]') {
				sortedProductsObj = {
					products: initialPBObj.products?.sort((a, b) =>
						a.displayName > b.displayName ? 1 : -1
					),
				};
				setFilteredPbData(sortedProductsObj);
			} else if (sortBy === 'PRODUCT NAME[Z-A]') {
				sortedProductsObj = {
					products: initialPBObj.products?.sort((a, b) =>
						b.displayName > a.displayName ? 1 : -1
					),
				};
				setFilteredPbData(sortedProductsObj);
			} else if (sortBy === 'PRODUCT CODE') {
				sortedProductsObj = {
					products: initialPBObj.products?.sort((a, b) =>
						a.publishedCatalogProductId > b.publishedCatalogProductId ? 1 : -1
					),
				};
				setFilteredPbData(sortedProductsObj);
			} else {
				sortedProductsObj = {
					products: initialPBObj.products?.sort(
						(a, b) => a.orderSeq - b.orderSeq
					),
				};
				setFilteredPbData(sortedProductsObj);
			}
		};
		const setQuickFilter = (selectedFilterItems) => {
			setFilteredQuickData(selectedFilterItems);
		};

		const removeFilteredItem = (groupObj, filterOptions) => {
			if (groupObj && filterOptions) {
				if (groupObj.filterGroupOsr === 'CARD_COLORS') {
					let filterOsrs = [];
					groupObj.filters.map((filter) => {
						if (
							filter.checked &&
							filter.filterOsr !== filterOptions.filterOsr
						) {
							filterOsrs = [...filterOsrs, ...filter.filterOptionOsrs];
						}
					});
					sessionStorage.setItem(
						'filterOptionOsrs',
						JSON.stringify(filterOsrs)
					);
				}
				if (groupObj.filterGroupOsr === '05-METAL-COLOR') {
					let metalOptions = JSON.parse(
						sessionStorage.getItem('selectedMetalOptions')
					);
					metalOptions = metalOptions.filter(
						(e) => e !== filterOptions.filterOsr
					);
					sessionStorage.setItem(
						'selectedMetalOptions',
						JSON.stringify(metalOptions)
					);
				}
				setFilteredQuickData(
					filteredItems.map((list) =>
						list.id === groupObj.id
							? {
									...list,
									filters: list.filters.map((filterObj) =>
										filterObj.filterName === filterOptions.filterName
											? {
													...filterObj,
													checked: false,
											  }
											: filterObj
									),
							  }
							: list
					)
				);
				setRemoveFiltersFlag(true);
			} else if(groupObj==="customClose") {
				getProducts({"filterName": "All College Jewelry"});
			} else {
				getFilterData();
				setFilteredPbData(pbData);
				setSelectedGroup({});
				sessionStorage.removeItem('filterOptionOsrs');
				sessionStorage.removeItem('selectedMetalOptions');
			}
		};

		useEffect(() => {
			if (removedFiltersFlag) {
				submitFilterData(filteredItems);
				setRemoveFiltersFlag(false);
			}
			// eslint-disable-next-line
		}, [removedFiltersFlag]);

		const submitFilterData = (lists) => {
			const filteredProducts = filterProducts(lists, pbData?.products, isOrpFilter, fieldToCompare)
			setQuickFilter(lists);
			setFilteredPbData({ ...pbData, products: filteredProducts})
			setShow(false);
		};

		const getFilterData = () => {
			let quickFilterData = { ...filterData };
			quickFilterData?.filterGroups?.map((filterGroup, index) => {
				filterGroup.id = index;
				filterGroup.active = false;
				filterGroup.filters?.length > 0 &&
					filterGroup.filters.map((filterObj) => {
						if (filterObj.filterOsr === selectedGroup?.filterOsr) {
							filterObj.checked = true;
						} else {
							filterObj.checked = false;
						}
						return true;
					});
				return true;
			});
			setFilteredQuickData &&
				setFilteredQuickData(
					quickFilterData?.filterGroups?.length
						? [...quickFilterData?.filterGroups]
						: []
				);
		};

		useEffect(() => {
			if (pbData && Object.keys(pbData)?.length > 0) {
				if (
					selectedGroup &&
					Object.keys(selectedGroup)?.length > 0 &&
					filterData &&
					Object.keys(filterData)?.length
				) {
					getFilterData();
					setSelectedGroup({});
				} else if (filterData && Object.keys(filterData)?.length) {
					let quickFilterData = { ...filterData };
					searchParamsItems?.length > 0 &&
						searchParamsItems.map((searchItem) =>
							quickFilterData?.filterGroups?.filter((filterGroup, index) => {
								filterGroup.id = index;
								filterGroup.active = false;
								filterGroup.filters?.length > 0 &&
									filterGroup.filters.map((filterObj) => {
										if (filterObj.filterOsr === searchItem) {
											filterObj.checked = true
										}
										return true;
									});
								return true;
							})
						);
					submitFilterData([...quickFilterData?.filterGroups]);
				} else {
					setFilteredPbData(pbData);
				}
			}
			// eslint-disable-next-line
		}, [pbData, selectedGroup, filterData]);

		useEffect(() => {
			let selectedFilters = 0;
			let payloadObj = [];
			if (filteredItems?.length) {
				filteredItems?.map((groupObj) =>
					groupObj.filters?.map((filterObj) => {
						if (filterObj.checked) {
							payloadObj.push(
								`${groupObj.filterGroupOsr}.${filterObj.filterOsr}`
							);
							selectedFilters++;
						}
					})
				);
			}
			setFilterItemsCount(selectedFilters);
			if (
				additionalData &&
				additionalData.publishedCatalogId &&
				additionalData.catalogType &&
				additionalData.customerId
			) {
				let url = `/apps/store/rest/filters/catalog/${additionalData.publishedCatalogId}/${additionalData.catalogType}/customer/${additionalData.customerId}`;
				axios({
					method: 'post',
					url: url,
					headers: {
						'Content-Type': 'application/json',
					},
					data: payloadObj,
				})
					.then((res) => console.log(res))
					.catch((err) => console.error(err));
			}
		}, [filteredItems]);

		useEffect(() => {
			!searchParamsItems?.length && getFilterData();
		}, [filterData]);

		const availableFilters = useMemo(() =>
			filteredItems?.map(filteredItem => ({
				...filteredItem,
				filters: getFiltersByExistingProducts(filteredItem.filters, pbData?.products || [])
			})) || []			
		, [filteredItems, pbData?.products])

		return (
			<>
				<div className='container-fluid'>
					{filterData && Object.keys(filterData).length > 0 && pbData?.products && !pbData?.products[0]?.customFilterProduct && (
						<button
							type='button'
							onClick={() => setShow(true)}
							className={`filter-button mr-3 ml-1 ${
								topFilterClicked && filteredPbData?.products?.length <= 6
									? 'd-none'
									: 'd-inline'
							}`}>
							<i className='fa fa-filter px-2'></i>FILTER
						</button>
					)}
					<label htmlFor='sortBtn' className='d-none'>
						Sort
					</label>
					<select
						id='sortBtn'
						className='sort-button'
						onChange={(e) => handleSort(e.target.value)}>
						{SortValues.map((name, index) => {
							return (
								<option key={index} value={name}>
									{name}
								</option>
							);
						})}
					</select>
					<span className='fontSizeXS ml-2'>
						{filterItemsCount === 0 && productCount ? productCount + ' Items'
							: filteredPbData?.products?.length > 0
							? filteredPbData.products.length + ' Items'
							: filteredItems?.length > 0 && filterItemsCount > 0
							? 'No results match your selections. Try undoing some filters.'
							: pbData?.products?.length + ' Items'}
					</span>
				</div>
				{filteredItems?.length > 0 && filterItemsCount > 0 && (
					<div className='px-4 mt-3'>
						{filteredItems?.map((groupObj) =>
							groupObj.filters?.map((filterObj) =>
								filterObj.checked ? (
									<span className='filter-items fontSizeXS p-1 m-1'>
										{filterObj.filterName}
										<i
											onClick={() => removeFilteredItem(groupObj, filterObj)}
											className='fa fa-times px-2 cursorPointer'></i>
									</span>
								) : null
							)
						)}
						{
							<span className='filter-items fontSizeXS p-1 m-1'>
								Clear All{' '}
								<i
									onClick={() => removeFilteredItem()}
									className='fa fa-times px-2 cursorPointer'></i>
							</span>
						}
					</div>
				)}

				{pbData?.products && pbData?.products[0]?.customFilterProduct && (
					<div className='px-4 mt-3'>
						<span className='filter-items fontSizeXS p-1 m-1'>
							{pbData?.products[0]?.filterNameToDisplay}
							<i
								onClick={() => removeFilteredItem("customClose")}
								className='fa fa-times px-2 cursorPointer'></i>
						</span>
						<span className='filter-items fontSizeXS p-1 m-1'>
							Clear All{' '}
							<i
								onClick={() => removeFilteredItem("customClose")}
								className='fa fa-times px-2 cursorPointer'></i>
						</span>
					</div>
				)}

				<Modal
					show={show}
					onHide={() => setShow(false)}
					dialogClassName='modal-90w tile-left'>
					<Modal.Header closeButton>
						<Modal.Title>Filter</Modal.Title>
					</Modal.Header>
					<Modal.Body className='pt-2 pb-3'>
						<FilterComponent
							submitFilterData={(list) => {
								submitFilterData(list);
								filterClicked && filterClicked();
							}}
							filteredItems={availableFilters}
							isUSCustomer={isUSCustomer}
						/>
					</Modal.Body>
				</Modal>
			</>
		);
	}
);
