export const getFiltersByExistingProducts = (filters, products) => {
    if (!filters || !products) return []

    const productOsrs = products.map(currentProduct => currentProduct.productOsr)

    return filters?.reduce((acc, curr) => {
        if(curr?.filterItems) {
            const isGroupContainsAnyProduct = curr?.filterItems.find(item => productOsrs.includes(item))
            isGroupContainsAnyProduct && acc.push(curr)
        }

        return acc
    }, [])
}