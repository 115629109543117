import React, { useEffect, useState } from 'react';
import { ProductBrowseTiles } from '../home/productBrowseTiles';
import { ProductFilterTile } from '../hs-jwl/productFilterTile';
import Select from 'react-select';
import { selectdropdown } from "../../util/googleAnalytics";
import { RegaliaUxDialog } from '../../components/regalia-ux'
import { useGetRegaliaUxDialogData } from '../../hooks/useGetRegaliaUxDialogData';
import { filterProducts } from '../../util/filterProducts';

export const ColRegaliaDialogUx = React.memo((props) => {
	const [filteredPbData, setFilteredPbData] = useState({});
	const [filteredItems, setFilteredQuickData] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState({});
	const [productData, setProductData] = useState({"products": []});
	const [subCatProductData, setSubCatProductData] = useState({});
	const [selectedOption, setSelectedOption] = useState({});
	const [catalogOptions, setCatalogOption] = useState([]);
	const [subCatFilterData, setSubCatFilterData] = useState({});
	const searchParamsDegree = new URLSearchParams(decodeURIComponent(window.location.search))?.get('degree');
	const [searchParamsDisabled, setSearchParamsDisabled] = useState(new URLSearchParams(decodeURIComponent(window.location.search))?.get('subCatalogDisabled'));

	const [isRegaliaDialogVisible, setIsRegaliaDialogVisible] = useState(true);
	const regaliaUxDialogData = useGetRegaliaUxDialogData(props?.serviceData, props?.filterData, productData?.products)

	useEffect(() => {
		setSubCatProductData((productData?.products?.length>1 && Object.keys(filteredPbData)?.length > 0) ? { "products": [...filteredPbData.products] } : { "products": [...productData?.products?.sort((a, b) => a.orderSeq - b.orderSeq)] })
	}, [productData, filteredPbData]);

	useEffect(() => {
		if (props.serviceData?.subCatalogRefKeys?.length > 0) {
			const options = [];
			props.serviceData?.subCatalogRefKeys?.forEach((subCatalog) => {
				let opt = {}
				opt.value = subCatalog.catalogKey
				opt.label = subCatalog.catalogName
				options.push(opt);
			});
			setCatalogOption(options);
			if(searchParamsDegree) {
				let opt1 = options.find(o=>o?.value?.includes(searchParamsDegree));
				setSelectedOption(opt1);
			} else if(props.serviceData?.subCatalogRefKeys?.length === 1) {
				setSelectedOption(options[0]);
			}
			searchParamsDisabled && searchParamsDisabled=="true"?setSearchParamsDisabled(true):setSearchParamsDisabled(false);
		} else {
			props.serviceData?.productGroups &&
				Object.keys(props.serviceData?.productGroups)?.length > 0 &&
				Object.keys(props.serviceData?.productGroups).forEach(optionKey => {
					setProductData({ "products": props.serviceData?.productGroups[optionKey]?.products })
					setSubCatFilterData(props.serviceData?.productGroups[optionKey]?.products?.length > 6 ? props.filterData : {})
					}
				)
		}
	},[props.serviceData])

	useEffect(() => {
		if (selectedOption && Object.keys(selectedOption)?.length > 0) {
			//selectdropdown(selectedOption?.label); // TODO: check the name of this function and the event name
			let selectedOptionProducts = {};
			const selectedSubCatalog = Object.values(props.serviceData?.subCatalogs || {})
				.find(el => el.publishedCatalogId === selectedOption?.value)
			selectedSubCatalog?.productGroups &&
				Object.keys(selectedSubCatalog?.productGroups)?.length > 0 &&
				Object.keys(selectedSubCatalog?.productGroups).forEach(
					(optionKey) => (selectedOptionProducts = selectedSubCatalog?.productGroups[optionKey])
				);
			let products = selectedOptionProducts.products;
			setProductData(
				Object.keys(selectedOptionProducts)?.length > 0
					? { products: products }
					: { products: [] }
			);
			if (products?.length > 6) {
				let catalogFilters = { ...props.filterData };
				props.filterData?.filterGroups?.forEach((filterObjs, index) => {
					let filter = filterObjs.filters?.filter((filterObj) => {
						let isApplicable = false;
						products.forEach((productObj) => {
							if (filterObj.filterItems.indexOf(productObj.productOsr) != -1) {
								isApplicable = true;
							}
						});
						return isApplicable;
					});
					catalogFilters.filterGroups[index].filters = [...filter];
				});
				setSubCatFilterData(catalogFilters);
			} else {
				setSubCatFilterData({});
			}
		}
	}, [selectedOption]);

	if (props.serviceData) {
		return (
			<>
				<RegaliaUxDialog 
					isVisible={isRegaliaDialogVisible} 
					data={regaliaUxDialogData} 
					onHide={()=>setIsRegaliaDialogVisible(false)}
					onSelectCatalog={setSelectedOption}
					onSelectDegree={filterList => {
						setFilteredQuickData(filterList);
						const filteredProducts = filterProducts(filterList, productData?.products, false, props?.fieldToCompare)
						setFilteredPbData({products: filteredProducts})
						setIsRegaliaDialogVisible(false)
					}}
				/>
				{ Object.keys(selectedOption)?.length > 0 && (
					<>
						{Object.keys(productData)?.length>0 && productData?.products?.length > 1 && <ProductFilterTile
							pbData={productData}
							filteredPbData={filteredPbData}
							filterData={subCatFilterData}
							setFilteredPbData={setFilteredPbData}
							selectedGroup={selectedGroup}
							setFilteredQuickData={setFilteredQuickData}
							filteredItems={filteredItems}
							setSelectedGroup={setSelectedGroup}
							fieldToCompare={props.fieldToCompare}
						/>}						
						<ProductBrowseTiles
							pbData={subCatProductData}
							pageContent={{...props.pageContent,"title":`${selectedOption?.label ? selectedOption?.label : ''} ${props.pageContent?.title}`}}
							productInfo={{"prodDescDotCms": false, checkCtaKey: "onlineOrderableFlag", "performSort": false }}
							isColRegalia={true}
						/>
					</>
				)}
			</>
		);
	} else {
		return null;
	}
});
