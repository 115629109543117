import { Modal } from 'react-bootstrap'
import { AppModalHeader, AppModalTitle, AppModalBody } from '../styled'

export const AppModal = ({
    visible,
    onHide,
    headerContent,
    children
}) => {
    return (
        <Modal show={visible} onHide={onHide} centered>
            <AppModalHeader closeButton>
                <AppModalTitle>{headerContent}</AppModalTitle>
            </AppModalHeader>
            <AppModalBody>
                {children}
            </AppModalBody>
        </Modal>
    )
}