import { Spinner, Background } from '../../basic/loader'
import { useGetConsumerDataById } from '../hooks'
import { redirectWithHashtagParams } from '../util'

export const RegaliaUxStudentConfirmationStep = ({ catalogActionUrl, consumerId, backButtonHandler }) => {
    const {consumerData, consumerDataLoading} = useGetConsumerDataById(consumerId)

    const isStudentConfirmationStepVisible = !consumerDataLoading && consumerData
    const isUnknownStudentStepVisible = !consumerDataLoading && !consumerData

    const confirmButtonHandler = () => {
        const params = {catalogOsr: consumerData?.catalogOsr, publishedCatalogId: consumerData?.publishedCatalogId, degree:consumerData?.degree}
        redirectWithHashtagParams(catalogActionUrl, params)
    }
   
    return (
        <>
            {consumerDataLoading && <Background><Spinner /></Background>}
            {isStudentConfirmationStepVisible && 
                <div className="student-confirmation-step">
                    <h3 className="ml-4">Id Confirmation</h3>
                    <p className="ml-4">Please confirm this information is correct</p>
                    <div className="btn-toolbar ml-4">
                        <div className="w-50">
                            <p>Name</p>
                            <p className="lead mb-5">{consumerData?.fullName ? consumerData?.fullName:"Not available"}</p>
                            {consumerData?.degree && 
                                <><p>Degree</p>
                                <p className="lead mb-5">{consumerData?.degree}</p>
                            </>}
                        </div>
                        <div className="w-50">
                                <p>Entered ID</p>
                                <p className="lead mb-5">{consumerData?.id}</p>
                            {consumerData?.fieldOfStudy && 
                            <><p>Field of Study</p>
                                <p className="lead mb-5">{consumerData?.fieldOfStudy}</p>
                            </>}
                        </div>
                        <div className="btn-toolbar w-100">
                            <div className="w-50">
                                <button className="btn btn-primary btn-primary--ghost back" onClick={backButtonHandler}>Back</button>   
                            </div>
                            <div className="w-50">
                                <button className="btn btn-primary" onClick={confirmButtonHandler}>Confirm</button>   
                            </div>
                        </div>
                    </div>
                    <p className="font-weight-bold mb-0 mt-4 ml-4">Incorrect ID?</p>
                    <p className="ml-4">Please contact your school for help</p>
                </div>
            }
            {isUnknownStudentStepVisible &&
                <div className="unknown-student-step">
                    <h3 className="ml-4">Id Confirmation</h3>
                    <p className="ml-4">Unable to confirm your ID. <br/>Please contact <a href="mailto:commencement@liberty.edu">commencement@liberty.edu</a> <br/>and place 'Grad Purchase Help' in the subject line.</p>
                    <button className="btn btn-primary btn-primary--ghost back" onClick={backButtonHandler}>Back</button>   
                </div>
            }
        </>
    )
}