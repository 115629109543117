import { useRef, useState } from 'react'
import { getCookie, setCookie } from '../../../util'
import { Spinner, Background } from '../../basic/loader'
import { RegaliaUxConstants } from '../constants'
import { redirectWithHashtagParams } from '../util'
import { RegaliaUxStudentConfirmationStep } from './RegaliaUxStudentConfirmationStep'

export const RegaliaUxStudentVerificationStep = ({ catalogActionUrl, stepsData, consumerSelection, customerNameListSettings, loading }) => {
    const [consumerId, setConsumerId] = useState()
    const studentIdInputRef = useRef(null)
    const catalogSelectRef = useRef(null)
    const availableCatalogData = stepsData && consumerSelection.catalogOsr && stepsData[RegaliaUxConstants.CATALOG_STEP][consumerSelection.catalogOsr]
    const [categoryInputValue, setCategoryInputValue] = useState("Select Category")
    const isStudentConsumerSelection = RegaliaUxConstants.STUDENT_CATALOG_OSR_LIST.includes(consumerSelection.catalogOsr)
    const consumerTypeLabel = isStudentConsumerSelection ? 'Student' : 'Faculty'
    const consumerVerificationType = isStudentConsumerSelection ? RegaliaUxConstants.STUDENT_VERIFICATION_TYPE : RegaliaUxConstants.FACULTY_VERIFICATION_TYPE
    
    const isConsumerVerificationOptional = customerNameListSettings
        .filter(customerNameListSetting => customerNameListSetting.type === consumerVerificationType)
        .reduce((acc, customerNameListSetting) => {
            return customerNameListSetting.option === 'OPTIONAL'
        }, false)
    
    const isStudentVerificationStepVisible = !loading && !consumerId
    const isStudentConfirmationStepVisible = !loading && consumerId

    if (isStudentConfirmationStepVisible) {
        setCookie("namelistId", consumerId)
    }

    const checkConsumerIdHandler = () => {
        const userInput = studentIdInputRef.current.value
        
        if (userInput) {
            setConsumerId(userInput)
        }
    }

    const shopNowButtonHandler = () => {
        const userCatalogSelection = catalogSelectRef.current.value
        const params = {catalogOsr: consumerSelection.catalogOsr, publishedCatalogId: userCatalogSelection}
        redirectWithHashtagParams(catalogActionUrl, params)
    }

    const backButtonHandler = ()=>{
        setConsumerId(null)
    }

    return (
        <>
            {loading && <Background><Spinner /></Background>}
            {isStudentVerificationStepVisible && 
                <div className="student-verification-step">
                   <h3>Enter {consumerTypeLabel} ID </h3>
                    <p>Enter {consumerTypeLabel} ID for graduation products.</p>
                    <div className="form-group">
                    <label htmlFor="student-id-input" className="font-weight-bold font-label text-black w-100">{consumerTypeLabel} ID</label>
                        <input id="student-id-input" type="number" inputMode="numeric" className="form-control w-60 d-inline" ref={studentIdInputRef} defaultValue={getCookie("namelistId")}></input>
                        <button className="btn btn-primary d-inline w-25 ml-4" onClick={checkConsumerIdHandler}>Search</button>
                    </div>
                    <p className="font-weight-bold mb-0 mt-4">Need Help?</p>
                    <p>Please contact your school for help</p>
                    { isConsumerVerificationOptional &&
                        <>
                            <hr/>
                            <h3>Search by collection </h3>
                            <div id="category-input-box">
                                <select id="catalog-selection" className="form-control w-60 d-inline" ref={catalogSelectRef} onChange={()=>setCategoryInputValue(document.getElementById("catalog-selection")?.value)}>
                                    <option value="Select Category">Select Category</option>
                                    {availableCatalogData.map((catalodData)=>{
                                        return <option key={catalodData.value} value={catalodData.value}>{catalodData.label}</option>
                                    })}
                                </select>
                                <button className="btn btn-primary d-inline w-25 ml-4" disabled={categoryInputValue==='Select Category'? true: false} onClick={shopNowButtonHandler}>Shop now</button>
                            </div>
                        </>
                    }
                </div>
            }
            {isStudentConfirmationStepVisible && <RegaliaUxStudentConfirmationStep catalogActionUrl={catalogActionUrl} consumerId={consumerId} backButtonHandler={backButtonHandler} />}
        </>
    )
}