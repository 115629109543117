import React, { useCallback, useEffect, useState } from 'react'
import { HeroBanner } from '../../components'
import { AlertsList } from '../../components/Alerts/alertsList'
import { BREADCRUMB, HEROBANNER, PAGETITLE, PAYPALBANNER, PRODUCTTILES, PROMOMSG } from '../../constants/rootElements'
import { Breadcrumb } from '../breadcrumb'
import { PaypalBanner } from '../paypalBanner'
import { TitleBar } from "../productBrowseTitle"
import { NO_JACKET_HERO_BANNER_MSG, SOFTSHELL_JACKET_BANNER_TITLE } from './constants'
import { useDataProcessor } from './hooks'
import { JktProductTiles } from "./jktProductTiles"

export const HSJacketsPage = props => {
    const appData = useDataProcessor(props.appData)
    const [pbData, setPbData] = useState({})
    const [breadcrumbData, setBreadcrupmData] = useState([])
    const [promoMessages, setPromoMessages] = useState([]);

    const JacketsTitleBar = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseTitle")
    const JacketsBreadcrumb = Boolean(appData?.pagecontentData?.find(data => data.contentType === "Navigation"))
    const JacketsHeroBanner = appData?.pagecontentData?.find(data => data.contentType === "DynamicHeroBanner")
    const ProductBrowseProducts = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseProducts")
    const PaypalBannerData = appData?.pagecontentData?.find(data => data.contentType === "PaypalId")
    const PaymentOptions = appData?.pagecontentData?.find(data => data.contentType ===  "PaymentOptionsDetail");
    
    const ProductBrowsePromoMsg = Boolean(
		appData?.pagecontentData?.find(
			(data) => data.contentType === 'ProductBrowsePromoMsg'
		)
	);

    const getBreadcrumb = useCallback(() => {
        BREADCRUMB.render(<Breadcrumb pbData={breadcrumbData} />);
    }, [breadcrumbData])
    
    const getHeroBanner = useCallback(() => {
        const heading = pbData.hasSoftshelJacket ? SOFTSHELL_JACKET_BANNER_TITLE : JacketsHeroBanner.heading
        const subHeading = pbData.hasLetterJacket ? JacketsHeroBanner.subheading : NO_JACKET_HERO_BANNER_MSG

        HEROBANNER.render(<HeroBanner 
            heading = {heading}
            subHeading = {subHeading}
            imgURL={JacketsHeroBanner.imageUrl}
            imgAltText={JacketsHeroBanner.imgAltText}
        />);
    }, [JacketsHeroBanner, pbData.hasLetterJacket, pbData.hasSoftshelJacket])

    const productTiles = useCallback(() => {
        PRODUCTTILES.render(<JktProductTiles pbData={pbData} pageContent={ProductBrowseProducts} paymentOptions={PaymentOptions}/>);
        // eslint-disable-next-line
    }, [pbData, PaymentOptions])

    const paypalBanner = useCallback(() => {
       PAYPALBANNER.render(<PaypalBanner paypalBannerData={PaypalBannerData} />);
    }, [PaypalBannerData])

	const getPromoMsg = useCallback(() => {
		PROMOMSG.render(<AlertsList messages={promoMessages} />);
	}, [promoMessages]);

    const getTitleBar = useCallback(() => {
        PAGETITLE.render(<TitleBar pageContent={JacketsTitleBar} affiliationData={appData?.affiliationData}/>);
      },[])

    useEffect(() => {
        Object.keys(appData.productBrowseData).map((key) => {
            if (key !== "metaData") {
                appData.productBrowseData[key]?.productGroups && Object.keys(appData.productBrowseData[key]?.productGroups).map((catObj) => {
                    setPbData(appData.productBrowseData[key].productGroups[catObj])
                    setPromoMessages(appData.productBrowseData[key].promoMessages);
                    setBreadcrupmData(appData.productBrowseData[key].productGroups[catObj])
                    return true
                })
            }
            return true
        })
    }, [appData.productBrowseData])

    return (
        <>
            {
                JacketsTitleBar && Object.keys(JacketsTitleBar)?.length > 0 && getTitleBar()
            }
            {
                ProductBrowsePromoMsg && promoMessages?.length > 0 && getPromoMsg()
            }
            {
                JacketsBreadcrumb && getBreadcrumb()
            }
            {
                JacketsHeroBanner && getHeroBanner()
            }
            {
                ProductBrowseProducts && Object.keys(ProductBrowseProducts)?.length > 0 && productTiles()
            }
            {
                PaypalBannerData && Object.keys(PaypalBannerData)?.length > 0 && paypalBanner()
            }

        </>
    )
}
