import { Button } from "react-bootstrap"

export const RegaliaUxCtaButtons = ({ data, singleColumn, onClick }) => {

    const containerClass = singleColumn ? "" : "d-flex"
    const columnClass = singleColumn ? "w-100" : "w-50"

    return (
        <div className={containerClass}>
            {data?.map((cta) => (
                <div key={cta.value} className={`my-4 ${columnClass}`}>
                    <Button className='w-75' onClick={onClick} value={cta.value}>{cta.label}</Button>
                </div>
            ))}
        </div>
    )
}