import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ProductBrowseTiles } from '../home/productBrowseTiles';
import { ProductFilterTile } from '../hs-jwl/productFilterTile';

export const JktProductTiles = React.memo((props) => {
	const [filteredPbData, setFilteredPbData] = useState({});
	const [productData, setProductData] = useState({});
	const [softshellJecketProductData, setSoftshellJecketProductData] = useState({});
	const [jktOption, setJktOption] = useState();
	const [jktDesc, setJktDesc] = useState();

	useEffect(() => {
		const productDataObj = {}
		const softshellJecketProductDataObj = {}

		if (Object.keys(filteredPbData)?.length > 0) {
			productDataObj.products = filteredPbData ? [...filteredPbData.products] : []
		} else {
			productDataObj.products = props.pbData?.products ? [...props.pbData.products] : []
			productDataObj.products.sort((a, b) => a.orderSeq - b.orderSeq)			  
		}

		if (props.pbData.hasSoftshelJacket) {
			softshellJecketProductDataObj.products = productDataObj.products.filter(product => product.isSoftshelJacket)
			productDataObj.products = productDataObj.products.filter(product => !product.isSoftshelJacket)
		}

		setProductData(productDataObj);
		setSoftshellJecketProductData(softshellJecketProductDataObj);
	}, [props.pbData, filteredPbData]);

	useEffect(() => {
		const jktOptionUrl = `${process.env.REACT_APP_BASE_PATH}/application/content/HS-JKTS/jacket-options.html`;
		axios
			.get(jktOptionUrl, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((data) => {
				setJktOption(data.data);
			});
		const jktDescpUrl = `${process.env.REACT_APP_BASE_PATH}/application/content/HS-JKTS/jacket/long-description`;
		axios
			.get(jktDescpUrl, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((data) => {
				setJktDesc(data.data);
			});
	}, []);

	if (props.pbData) {
		return (
			<>
				<ProductFilterTile
					pbData={props.pbData}
					filteredPbData={filteredPbData}
					setFilteredPbData={setFilteredPbData}
				/>

				{ productData.products?.length > 0 &&
					<ProductBrowseTiles
						pbData={productData}
						pageContent={props.pageContent}
						paymentOptions={props.paymentOptions}
						productInfo={{"jktOption":jktOption,"jktDesc":jktDesc, "fromJkt": true, performSort:false}}
					/>
				}
				{ props.pbData.hasSoftshelJacket &&
					<ProductBrowseTiles
						pbData={softshellJecketProductData}
						pageContent={{...props.pageContent, title: 'Outerwear'}}
						productInfo={{"jktOption":jktOption,"jktDesc":jktDesc, "fromJkt": true, performSort:false}}
					/>
				}
			</>
		);
	} else {
		return null;
	}
});
