import styled from 'styled-components/macro'

export const Background = styled.section`
	height: 100%;
	width: 100%;
	position: fixed;
	background: rgba(0,0,0,.5);
	top: 0;
	left: 0;
	z-index: 14;
	display: flex;
    align-items: center;
    justify-content: center;
`

export const Spinner = styled.div`
	display: inline-block;
	width: ${props => props.size ? props.size : "100px"};
	height: ${props => props.size ? props.size : "100px"};
	border: ${props => props.thickness ? props.thickness : "10px"} solid rgba(255,255,255,.3);
	border-radius: 50%;
	border-top-color: #fff;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;
	@keyframes spin {
      to { -webkit-transform: rotate(360deg); }
	}
	@-webkit-keyframes spin {
      to { -webkit-transform: rotate(360deg); }
	}
`