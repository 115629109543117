export const sliderSettings = {
    accessibility: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    dots: true,
    variableWidth: false,
    adaptiveHeight: false,
    infinite: false,
    responsive: [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                variableWidth: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: true,
                arrows: true,
                variableWidth: false,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                dots: true,
                arrows: true,
                variableWidth: false,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                dots: true,
                arrows: true,
                variableWidth: false,
            },
        },
    ],
};

export const containerSettings = {
    show: {
        transition: {
            staggerChildren: 0.2,
        },
    },
};

export const productSettings = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            duration: 0.25,
        },
    },
};