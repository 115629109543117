export const RegaliaUxConstants = Object.freeze({
    FEATURE_FLAG_NAME: "customerHasSY2025UX",

    DEFAULT_DIALOG_TITLE: "Find the right Regalia products for graduation",
    DEFAULT_DIALOG_SUBTITLE: "Select an option to view custom products.",

    STUDENT_CATALOG_OSR_LIST: [ "col_greg_std", "col_greg_std_pkg" ],
    FACULTY_CATALOG_OSR_LIST: [ "col_greg_fac", "col_greg_fac_pkg" ],

    DEGREE_FILTER_GROUP_OSR: "DEGREE",

    CONSUMER_TYPE_STEP: "ConsumerTypeStep",
    CATALOG_STEP: "CatalogStep",
    DEGREE_STEP: "DegreeStep",
    STUDENT_VERIFICATION_STEP: 'StudentVerificationStep',
    STUDENT_CONFIRMATION_STEP: 'StudentConfirmationStep',

    STUDENT_VERIFICATION_TYPE: 'STUDENT',
    FACULTY_VERIFICATION_TYPE: 'FACULTY'
    
})