import React, { useState, useEffect } from 'react'
import { ProductBrowseTiles } from '../home/productBrowseTiles'
import { ProductFilterTile } from './productFilterTile'
import { useGetExcludeListDataByCustomerId } from'./hooks'

export const HsProductBrowse = props => {
    const [scroll, setScroll] = useState(false);
    const [productData, setProductData] = useState([])
    const [modFilterData, setModFilterData] = useState({})    
    const metalOptionOsrs = JSON.parse(sessionStorage.getItem("selectedMetalOptions"))

    const { isCustomerInCatalogExcludeList } = useGetExcludeListDataByCustomerId(
        props.excludeList, 
        props.catalogOSR,
        props.additionalData?.customerId
    )

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY < 10);

        });
    }, []);

    useEffect(() => {
        return sessionStorage.removeItem("selectedMetalOptions")
    }, [])

    useEffect(() => {
        let tempFilterObj = {...props.filterData};
        if(props?.pbData?.products?.length>0) {
            props?.filterData?.filterGroups.forEach((filter, index) => {
                filter?.filters?.forEach((subFilter, innerIndex) => {
                    let foundProducts = props?.pbData?.products?.filter((product)=> {
                        return subFilter?.filterItems?.includes(product.productOsr);
                    })
                    if(foundProducts?.length==0) {
                        tempFilterObj?.filterGroups[index]?.filters?.splice(innerIndex, 1);
                    }
                });
            })
            setModFilterData(tempFilterObj);
        } else if(props?.pbData?.products?.length==0) {
            setModFilterData({});
        }
    }, [props?.pbData])

    const getFilteredMetalProducts = () => {
        const productsData = { ...props.filteredPbData }
        productsData.products?.length > 0 && productsData.products.map((pObj, index) => {
            const flavouredProdObj = {}
            const flavouredimageUrl = `${pObj.imageUrl.replace("version=2", "version=3")}&option=v1/${metalOptionOsrs?.length > 0 && metalOptionOsrs[0]}`
            flavouredProdObj.imageUrl = flavouredimageUrl
            productsData.products[index] = { ...productsData.products[index], ...flavouredProdObj }
        })
        setProductData({ "products": [...productsData?.products] })
    }
    
    useEffect(()=> {
        if(metalOptionOsrs?.length > 0){
            getFilteredMetalProducts()
        }else {
            setProductData({ "products": [...(props?.filteredPbData?.products || [])] })
        }
    },[props.filteredPbData])
    
    if(props.pbData){
        return (
            <>
                <div>
                    <span className="px-2 pt-2 h5">{props.fromHsGrad ? props.pbData?.displayName : "Individual Products"}</span>
                    {!props.fromHsGrad && <span className="fontSizeXS ml-3 text-underline cursorPointer" onClick={props.shopAll}>{`Shop all (${props.pbData?.products?.length})`}</span>}
                </div>
                <div className='container-fluid'><hr /></div>
                <div className={window.scrollY === 0 ? "filter" : scroll ? "filter" : "filter-in"}>
                { props.ProductBrowseFiltersBar && (<ProductFilterTile 
                        pbData={props.pbData} 
                        filteredPbData={props.filteredPbData} 
                        filterData={modFilterData} 
                        setFilteredPbData={props.setFilteredPbData} 
                        selectedGroup={props.selectedGroup}
                        setFilteredQuickData={props.setFilteredQuickData} 
                        filteredItems={props.filteredItems} 
                        setSelectedGroup={props.setSelectedGroup} 
                        isUSCustomer={props.isUSCustomer} 
                        additionalData={props.additionalData} 
                    />)}
                </div> 
                <ProductBrowseTiles 
                    pbData={Object.keys(productData)?.length > 0 ? productData : {"products":[...(props.pbData?.products?.sort((a, b) => a.orderSeq - b.orderSeq) || [])]}} 
                    fromHsJwl={true} 
                    pageContent={props.pageContent} 
                    productInfo={{"performSort": false}}
                    promoCodes={props.promoCodes}
                    isCustomerInExcludeList={isCustomerInCatalogExcludeList}
                /> 
               
            </>
	    )
    }else{
        return null
    }
}
