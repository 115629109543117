import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './global.css';
import './customer.css';
import './ecom.css';
import './cms.css';
// import './ecom-prochamp.css';
// import './ecom-yearbook.css';
// import './ecom-uploma.css';
// import './ecom-hs-jwl.css';
// import './ecom-diploma.css';
// import './ecom-hs-grad.css';
// import './ecom-hs-jkts.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import App from './app';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();