import { RegaliaUxConstants } from '../constants'
import { selectFilterState } from '../util'
import { RegaliaUxCtaButtons } from './RegaliaUxCtaButtons'

export const RegaliaUxDegreeStep = ({ stepsData, filterData, onClick }) => {

    const data = stepsData && stepsData[RegaliaUxConstants.DEGREE_STEP]

    const handleClick = (selectedFilterOsr) => {
        if (filterData?.filterGroups) {
            const updatedFilterData = selectFilterState(filterData.filterGroups, RegaliaUxConstants.DEGREE_FILTER_GROUP_OSR, selectedFilterOsr)
            onClick && onClick({ selectedFilterOsr, updatedFilterData })
        }
    }

    return (
        <>
            <h4 className="text-uppercase font-weight-bold">{RegaliaUxConstants.DEFAULT_DIALOG_TITLE}</h4>
            <p className="lead small">{RegaliaUxConstants.DEFAULT_DIALOG_SUBTITLE}</p>
            <RegaliaUxCtaButtons data={data} onClick={e => handleClick(e.target.value)} singleColumn={true} />
        </>
    )
}