import { domainConverter } from "../../../../util"
import { Image } from 'react-bootstrap'

const LOGO_URL = "https://cdn.jostens.com/application/themes/jostens/images/jostens-logo.svg";

export const JostensLogo = () => {

    return (
        <Image alt="Jostens Logo" src={domainConverter(LOGO_URL)} />
    )
}