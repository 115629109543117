import styled from 'styled-components/macro'
import { Modal } from 'react-bootstrap'

export const AppModalHeader = styled(Modal.Header)`
    border-bottom: none;
    text-align: center;
`

export const AppModalTitle= styled(Modal.Title)`
    width: 100%;
    margin-left: 34px;
    margin-top: 15px;
`

export const AppModalBody = styled(Modal.Body)`
    text-align: center;
`