import { useState, useEffect } from 'react'
import { RegaliaUxConstants } from '../components/regalia-ux'
import { getFiltersByExistingProducts } from '../util/getFiltersByExistingProducts'

export const useGetRegaliaUxDialogData = (catalog, filterData, products) => {
    const [data, setData] = useState()

    useEffect(()=>{
        if (catalog && catalog.subCatalogs) {
            // take only unique catalogOsrs for Consumer Type Step 
            const subCatalogsArray = Object.keys(catalog.subCatalogs)?.map(key => ({
                ...catalog.subCatalogs[key]
            }))
            const consumerTypeStep = [... new Set(subCatalogsArray.map(subCatalog => subCatalog.catalogOsr))]
            
            // create required data schema for Catalog Step 
            let catalogStepInitialData = Object.fromEntries(consumerTypeStep.map(element => [element, []]))
            const catalogStep = subCatalogsArray.reduce((acc, subCatalog) => {
                acc[subCatalog.catalogOsr].push({
                    label: subCatalog.catalogName,
                    value: subCatalog.publishedCatalogId
                })
                return acc
            }, catalogStepInitialData)

            const degreeFilterGroup = filterData?.filterGroups?.find(el => el.filterGroupOsr === RegaliaUxConstants.DEGREE_FILTER_GROUP_OSR)
            const degreeStep = getFiltersByExistingProducts(degreeFilterGroup?.filters, products)
                ?.map(({ filterName, filterOsr }) => ({ 
                    label: filterName, 
                    value: filterOsr
                }))
            
            const preparedStepData = {
                [RegaliaUxConstants.CONSUMER_TYPE_STEP]: consumerTypeStep,
                [RegaliaUxConstants.CATALOG_STEP]: catalogStep,
                [RegaliaUxConstants.DEGREE_STEP]: degreeStep
            }

            setData({ 
                catalogActionUrl: catalog.actionUrl, 
                stepsData: preparedStepData,
                filterData
            })
        } 

    }, [catalog, filterData, products])

    return data
}