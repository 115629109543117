import { RegaliaUxConstants } from '../constants'
import { RegaliaUxCtaButtons } from './RegaliaUxCtaButtons'

export const RegaliaUxCatalogStep = ({ stepsData, consumerSelection, onClick }) => {

    const data = stepsData && consumerSelection.catalogOsr && stepsData[RegaliaUxConstants.CATALOG_STEP][consumerSelection.catalogOsr]

    return (
        <>
            <h4 className="text-uppercase font-weight-bold">{RegaliaUxConstants.DEFAULT_DIALOG_TITLE}</h4>
            <p className="lead small">{RegaliaUxConstants.DEFAULT_DIALOG_SUBTITLE}</p>
            <RegaliaUxCtaButtons data={data} onClick={onClick} singleColumn={true} />
        </>
    )
}