import { actionUrlConverter } from '../../../util'
/**
 * Compile and initiate redirect to catalog action url with hashtag filtering parameters based on available data
 * @param {string} catalogActionUrl Catalog PLP url 
 * @param {Object} params {catalogOsr, publishedCatalogId, degree}
 */
export const redirectWithHashtagParams = (catalogActionUrl, params) => {
    const url = new URL(actionUrlConverter(catalogActionUrl))
    const hashParams = new URLSearchParams();
    Object.keys(params).filter(key => params[key]).forEach(key => hashParams.append(key, params[key]))
    url.hash = hashParams.toString()
    window.open(url, '_self')
}

/**
 * Get customer number by parsing query string parameters 
 * @returns {string} path name parameter at 4th index
 */
export const getCustomerNumber = () => {
    const pathNameParts = window.location.pathname.split('/')
    return pathNameParts && pathNameParts[4]
}

/**
 * Selects the specified filter state
 * @param {Array} filterGroups current filter state
 * @param {string} filterGroupOsr filterGroup where filterOsr is contained
 * @param {string} filterOsr filterOsr to be selected
 * @returns updated filter state with selected filter osr
 */
export const selectFilterState = (filterGroups, filterGroupOsr, filterOsr) => {
    const updatedFilterData = JSON.parse(JSON.stringify(filterGroups))
    const filterGroupToUpdate = updatedFilterData.find(filterGroup => filterGroup.filterGroupOsr === filterGroupOsr)
    if (filterGroupToUpdate) {
        const selectedFilter = filterGroupToUpdate.filters?.find(filter => filter.filterOsr === filterOsr)
        if (selectedFilter) {
            selectedFilter.checked = true
        }
    }
    return updatedFilterData
}

/**
 * Loads Regalia UX params from URL Hashtags
 * @returns loaded parameters
 */
export const loadRegaliaUxParamsFromUrlHashtags = () => {
    const hashtagParams = new URLSearchParams(window.location.hash.substring(1))
    const loadedParams = {
        catalogOsr: hashtagParams.get('catalogOsr'),
        publishedCatalogId: hashtagParams.get('publishedCatalogId'),
        degree: hashtagParams.get('degree')
    }
    return loadedParams
}